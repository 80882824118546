<template>
  <aside class="sidebar">
    <h2 class="for-mobile">Menu</h2>
    <ul>
      <li :class="{ current: $route.name == 'Message' }">
        <router-link to="/account/message">
          Messages
        </router-link>
      </li>
      <li
        :class="{
          current:
            $route.name == 'AccountTransaction' ||
            $route.name == 'AccountTransactionDetail'
        }"
      >
        <router-link to="/account/transaction">
          My Transactions
        </router-link>
      </li>
      <li
        :class="{
          current:
            $route.name == 'AccountMyProperties' ||
            $route.name == 'AccountMyPropertiesDetail'
        }"
      >
        <router-link to="/account/my-properties">
          My Listed Properties
        </router-link>
      </li>
      <li :class="{ current: $route.name == 'SavedProperties' }">
        <router-link to="/account/saved-properties">
          Saved Properties
        </router-link>
      </li>
      <li :class="{ current: $route.name == 'AccountRequestProperty' }">
        <router-link to="/account/request-property">
          Request Property
        </router-link>
      </li>
      <li
        :class="{
          current:
            $route.name == 'MemberPoint' || $route.name == 'MemberPointDetail'
        }"
      >
        <router-link to="/account/member-point">
          Member Points
        </router-link>
      </li>
      <li :class="{ current: $route.name == 'AccountSetting' }">
        <router-link to="/account/setting">
          Account Settings
        </router-link>
      </li>
    </ul>
    <div class="logout">
      <a
        href="javascript:"
        data-src="#popup-logout"
        class="cvr-bg-bf"
        data-fancybox
        >Sign Out</a
      >
    </div>
    <div id="popup-logout" class="popup-logout" style="display: none;">
      <h2>Are you sure <br />want to sign out?</h2>
      <a href="#" class="button btn-cancel">Cancel</a>
      <a href="#" class="button btn-confirm" @click="logout">Yes</a>
    </div>
    <!-- end of popup logout -->
  </aside>
</template>

<script>
import $ from "jquery";
import cookie from "js-cookie";

export default {
  name: "SidebarAccount",
  mounted() {
    $(".popup-logout .button.btn-cancel").on("click", function(e) {
      e.preventDefault();
      $(this)
        .closest(".popup-logout")
        .find(".fancybox-close-small")
        .trigger("click");
    });
    if (window.matchMedia("only screen and (max-width: 991px)").matches) {
      $(".inner-sidebar .sidebar h3").on("click", function() {
        $(this).toggleClass("expanded");
      });

      $(".inner-acc .sidebar h2.for-mobile").on("click", function() {
        $(this)
          .closest(".sidebar")
          .find("ul")
          .slideToggle("fast");
        $(this)
          .closest(".sidebar")
          .find(".logout")
          .slideToggle("fast");
      });
    }
  },
  methods: {
    logout() {
      cookie.remove("rubii");
      this.$store.commit("SET_USERDATA", null);
      window.location.reload();
    }
  }
};
</script>
