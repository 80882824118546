var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"sidebar"},[_c('h2',{staticClass:"for-mobile"},[_vm._v("Menu")]),_c('ul',[_c('li',{class:{ current: _vm.$route.name == 'Message' }},[_c('router-link',{attrs:{"to":"/account/message"}},[_vm._v(" Messages ")])],1),_c('li',{class:{
        current:
          _vm.$route.name == 'AccountTransaction' ||
          _vm.$route.name == 'AccountTransactionDetail'
      }},[_c('router-link',{attrs:{"to":"/account/transaction"}},[_vm._v(" My Transactions ")])],1),_c('li',{class:{
        current:
          _vm.$route.name == 'AccountMyProperties' ||
          _vm.$route.name == 'AccountMyPropertiesDetail'
      }},[_c('router-link',{attrs:{"to":"/account/my-properties"}},[_vm._v(" My Listed Properties ")])],1),_c('li',{class:{ current: _vm.$route.name == 'SavedProperties' }},[_c('router-link',{attrs:{"to":"/account/saved-properties"}},[_vm._v(" Saved Properties ")])],1),_c('li',{class:{ current: _vm.$route.name == 'AccountRequestProperty' }},[_c('router-link',{attrs:{"to":"/account/request-property"}},[_vm._v(" Request Property ")])],1),_c('li',{class:{
        current:
          _vm.$route.name == 'MemberPoint' || _vm.$route.name == 'MemberPointDetail'
      }},[_c('router-link',{attrs:{"to":"/account/member-point"}},[_vm._v(" Member Points ")])],1),_c('li',{class:{ current: _vm.$route.name == 'AccountSetting' }},[_c('router-link',{attrs:{"to":"/account/setting"}},[_vm._v(" Account Settings ")])],1)]),_vm._m(0),_c('div',{staticClass:"popup-logout",staticStyle:{"display":"none"},attrs:{"id":"popup-logout"}},[_vm._m(1),_c('a',{staticClass:"button btn-cancel",attrs:{"href":"#"}},[_vm._v("Cancel")]),_c('a',{staticClass:"button btn-confirm",attrs:{"href":"#"},on:{"click":_vm.logout}},[_vm._v("Yes")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logout"},[_c('a',{staticClass:"cvr-bg-bf",attrs:{"href":"javascript:","data-src":"#popup-logout","data-fancybox":""}},[_vm._v("Sign Out")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',[_vm._v("Are you sure "),_c('br'),_vm._v("want to sign out?")])}]

export { render, staticRenderFns }