<template>
  <div id="member-point-detail-page">
    <div class="inner-acc">
      <div class="wrapper rowflex">
        <sidebar-account></sidebar-account>
        <article class="main">
          <div class="inner-acc-setting">
            <div class="member-point">
              <div class="crumb">
                <router-link to="/account/member-point">
                  Member Points
                </router-link>
                <i></i>
                <span>Add Delivery Details</span>
              </div>
              <form>
                <h3>Add Delivery Details</h3>
                <div class="emul">
                  <div class="gap">
                    <fieldset>
                      <label>Recipient’s Name</label>
                      <input
                        type="text"
                        value=""
                        placeholder="Input text here"
                      />
                    </fieldset>
                    <fieldset>
                      <label>Phone Number</label>
                      <input
                        type="tel"
                        value=""
                        placeholder="Input text here"
                      />
                    </fieldset>
                  </div>
                  <!-- end of gap -->
                  <div class="gap">
                    <fieldset>
                      <label>Full Address</label>
                      <textarea placeholder="Input text here"></textarea>
                    </fieldset>
                    <div class="wrap">
                      <fieldset>
                        <label>City</label>
                        <input
                          type="text"
                          value=""
                          placeholder="Input text here"
                        />
                      </fieldset>
                      <fieldset>
                        <label>Province</label>
                        <input
                          type="text"
                          value=""
                          placeholder="Input text here"
                        />
                      </fieldset>
                    </div>
                  </div>
                  <!-- end of gap -->
                  <div class="gap">
                    <fieldset>
                      <label>Special Instructions</label>
                      <input
                        type="text"
                        value=""
                        placeholder="Input text here"
                      />
                    </fieldset>
                    <fieldset>
                      <label>Postal Code</label>
                      <input
                        type="text"
                        value=""
                        placeholder="Input text here"
                      />
                    </fieldset>
                  </div>
                  <!-- end of gap -->
                  <div class="centered">
                    <br /><br />
                    <button type="submit" class="button">Submit</button>
                  </div>
                </div>
              </form>
            </div>
            <!-- end of acc detail -->
          </div>
          <!-- end of inner accsetting -->
        </article>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner mort -->
  </div>
</template>

<script>
import SidebarAccount from "./SidebarAccount.vue";
export default {
  name: "MemberPointDetail",
  metaInfo: {
    title: "Member Point Detail"
  },
  components: {
    SidebarAccount
  }
};
</script>
